import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Tag,
  Text,
} from '@chakra-ui/react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ArrowRightIcon,
  DocumentMagnifyingGlassIcon,
} from '@heroicons/react/16/solid';
import {
  CurrencyEuroIcon,
  FolderArrowDownIcon,
  HomeIcon,
  HomeModernIcon,
} from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { price, size, status as statusFormatter } from '../../Services';
import './popup.css';

export default function Popup({ status, plot }) {
  const swiperRef = useRef(null);
  let priceTag = plot.price ? price(plot.price) : '';

  if (plot.status === 'verkocht') priceTag = 'Verkocht';
  if (plot.status === 'voorbereiden-start-verkoop')
    priceTag = 'Voorbereiden start verkoop';

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(0, 0);
    }
  }, [plot]);

  return (
    <>
      {plot.media.length > 0 && (
        <Flex position="relative" zIndex={0} userSelect="none">
          <Swiper
            ref={swiperRef}
            modules={[Navigation, Pagination]}
            slidesPerView={1}
            navigation={{
              nextEl: '.swiper-next',
              prevEl: '.swiper-prev',
            }}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {plot.media.map((media) => (
              <SwiperSlide key={media.id}>
                <img
                  src={media.url}
                  width={media.width}
                  height={media.height}
                  alt=""
                  loading="eager"
                  fetchPriority="high"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {plot.media.length > 1 && (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              position="absolute"
              width="100%"
              height="100%"
            >
              <PrevButton />
              <NextButton />
            </Flex>
          )}
        </Flex>
      )}

      <Grid px={4} pt={3} templateColumns="repeat(1, 1fr)" mb={6}>
        <Heading as="h3" size="lg">
          Bouwnr. {plot.number}
        </Heading>
        <Tag
          className="status"
          display="flex"
          textAlign="center"
          justifyContent="center"
          paddingY="1"
          w={'fit-content'}
          fontWeight={'normal'}
          backgroundColor={status.color}
          fontSize={'xs'}
          borderRadius={'sm'}
          mb={3}
        >
          {statusFormatter(plot.status)}
        </Tag>

        <Flex flexWrap={'wrap'} gap={3} justifyContent={'stretch'}>
          {plot.registration_page && (
            <Button
              as="a"
              textDecoration={'none'}
              href={plot.registration_page}
              fontWeight={'normal'}
              display={'flex'}
              variant="solid"
              fontSize={'sm'}
              target={'_blank'}
              rounded={'sm'}
              gap={2}
              alignItems={'center'}
              lineHeight={'1.25rem'}
              flex={'1 0 100%'}
              px={1}
            >
              <HomeIcon height={'1rem'} />
              Nu inschrijven
              <ArrowRightIcon height={'1rem'} />
            </Button>
          )}

          {plot.custom_cta_url && plot.custom_cta_text && (
            <Button
              as="a"
              textDecoration={'none'}
              href={plot.custom_cta_url}
              fontWeight={'normal'}
              display={'flex'}
              variant="outline"
              fontSize={'xs'}
              target={'_blank'}
              rounded={'sm'}
              gap={2}
              alignItems={'center'}
              lineHeight={'1.25rem'}
              flex={'1 0 auto'}
              px={1}
            >
              {plot.custom_cta_text}
              <ArrowRightIcon height={'1rem'} />
            </Button>
          )}
        </Flex>
      </Grid>

      <Grid
        templateColumns={plot.price ? 'repeat(2, 1fr)' : '1fr'}
        gap={2}
        px={4}
        fontSize={'sm'}
      >
        <GridItem
          w="100%"
          minHeight={'100px'}
          bg="blackAlpha.200"
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <Box
            color={'primary.bg.default'}
            flex={'1 0 auto'}
            display={'flex'}
            alignItems={'center'}
            py={2.5}
          >
            <HomeModernIcon
              width={'100%'}
              height={'2.25rem'}
              color={'primary.default.txt'}
              strokeWidth={'1'}
            />
          </Box>

          <Box
            as="span"
            py={2.5}
            textAlign={'center'}
            flex={'0 0 auto'}
            width={'full'}
          >
            {plot.type}
          </Box>
        </GridItem>

        {!!plot.price && (
          <GridItem
            bg="blackAlpha.200"
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            width={'full'}
            flexDirection={'column'}
            minHeight={'100px'}
          >
            <Box
              as="span"
              color={'primary.bg.default'}
              flex={'1 0 auto'}
              display={'flex'}
              alignItems={'center'}
              py={2.5}
            >
              <CurrencyEuroIcon
                width={'100%'}
                height={'2.25rem'}
                color={'primary.default.txt'}
                strokeWidth={'1'}
              />
            </Box>
            <Box
              as="span"
              py={2.5}
              textAlign={'center'}
              flex={'0 0 auto'}
              width={'full'}
            >
              {priceTag}
            </Box>
          </GridItem>
        )}

        {plot.parking_description > '' && (
          <GridItem colSpan={2}>
            <Box fontSize={'xs'} px={1} color={'#2d2d2d54'}>
              <Flex gap="1" alignItems={'center'}>
                <FontAwesomeIcon icon={regular('circle-parking')} />
                <Text as="span" marginLeft={0}>
                  {plot.parking_description}
                </Text>
              </Flex>
            </Box>
          </GridItem>
        )}
      </Grid>

      <Box
        mt={4}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        justifyContent={'center'}
        alignItems={'start'}
        fontSize={'sm'}
        px={4}
      >
        <Heading as="h5" size="sm" color={'primary.bg.default'}>
          Wonen
        </Heading>
        {plot.living_surface > 0 && (
          <Flex justifyContent={'space-between'} width={'100%'}>
            <p>Woonoppervlakte</p>
            <p>
              {size(plot.living_surface)}
              <sup>2</sup>
            </p>
          </Flex>
        )}
        {plot.plot_surface > 0 && (
          <Flex justifyContent={'space-between'} width={'100%'}>
            <p>Kaveloppervlakte</p>
            <p>
              {size(plot.plot_surface)}
              <sup>2</sup>
            </p>
          </Flex>
        )}
        {plot.room_count > 0 && (
          <Flex justifyContent={'space-between'} width={'100%'}>
            <p>Aantal kamers</p>
            <p>{plot.room_count}</p>
          </Flex>
        )}
        {plot.bedrooms > 0 && (
          <Flex justifyContent={'space-between'} width={'100%'}>
            <p>Aantal slaapkamers</p>
            <p>{plot.bedrooms}</p>
          </Flex>
        )}
        {plot.parking_count > 0 && (
          <Flex justifyContent={'space-between'} width={'100%'}>
            <p>Parkeerplaatsen</p>
            <p>{plot.parking_count}</p>
          </Flex>
        )}
      </Box>
      <Box display="flex" gap={2} padding={15}>
        {plot.downloads_page && (
          <Button
            as="a"
            textDecoration={'none'}
            href={plot.downloads_page}
            fontWeight={'normal'}
            display={'flex'}
            variant="outline"
            fontSize={'xs'}
            target={'_blank'}
            rounded={'sm'}
            gap={2}
            alignItems={'center'}
            lineHeight={'1.25rem'}
            flex={'1 0 auto'}
            px={5}
          >
            <FolderArrowDownIcon height={'1rem'} />
            Downloads
          </Button>
        )}
        {plot.detail_page && (
          <Button
            as="a"
            textDecoration={'none'}
            href={plot.detail_page}
            fontWeight={'normal'}
            display={'flex'}
            variant="outline"
            fontSize={'xs'}
            target={'_blank'}
            rounded={'sm'}
            gap={2}
            alignItems={'center'}
            lineHeight={'1.25rem'}
            flex={'1 0 auto'}
            px={4}
          >
            <DocumentMagnifyingGlassIcon height={'1rem'} />
            Woningtype
          </Button>
        )}
      </Box>
    </>
  );
}

function NextButton() {
  return (
    <Button
      className="swiper-next"
      zIndex={2}
      height="100%"
      width="2.5rem"
      alignItems="center"
      justifyContent={'center'}
      background="linear-gradient(to right, transparent, rgb(0,0,0,.75))"
      cursor="pointer"
      position="absolute"
      right={0}
    >
      <FontAwesomeIcon
        icon={faChevronRight}
        fontSize="1.125rem"
        color="white"
      />
    </Button>
  );
}

function PrevButton() {
  return (
    <Button
      className="swiper-prev"
      zIndex={2}
      height="100%"
      width="2.5rem"
      alignItems="center"
      justifyContent={'center'}
      background="linear-gradient(to left, transparent, rgb(0,0,0,.75))"
      cursor="pointer"
      position="absolute"
      left={0}
    >
      <FontAwesomeIcon icon={faChevronLeft} fontSize="18px" color="white" />
    </Button>
  );
}

Popup.propTypes = {
  status: PropTypes.object.isRequired,
  plot: PropTypes.object,
};
