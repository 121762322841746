/**
 *
 * @param {object} plot Represents a plot
 * @param {object} activeFilters Represents the values of the currently selected filters
 * @returns {boolean} Wether the plot matches the filter or not
 */
const matchesFilter = (plot, activeFilters) => {
  const inStatusFilter = activeFilters['status']
    ? statusFilter(plot, activeFilters.status)
    : 1;
  const inHouseTypeFilter = activeFilters['type']
    ? houseTypeFilter(plot, activeFilters.type)
    : 1;
  const inPriceFilter = activeFilters['price']
    ? priceFilter(plot, activeFilters.price)
    : 1;
  const inLivingSizeFilter = activeFilters['living-surface']
    ? livingSurfaceFilter(plot, activeFilters['living-surface'])
    : 1;
  const inParkingCountFilter = activeFilters['parking-count']
    ? parkingCountFilter(plot, activeFilters['parking-count'])
    : 1;
  const inPlotSurfaceFilter = activeFilters['plot-surface']
    ? plotSurfaceFilter(plot, activeFilters['plot-surface'])
    : 1;

  return (
    inStatusFilter &&
    inHouseTypeFilter &&
    inPriceFilter &&
    inLivingSizeFilter &&
    inParkingCountFilter &&
    inPlotSurfaceFilter
  );
};

/**
 * @param {object} plot Represents a plot
 * @param {object} statusFilter Value of the status filter
 * @returns {boolean} Wether the plot matches the filter or not
 */
function statusFilter(plot, statusFilter) {
  return Object.keys(statusFilter)
    .map((key) => key.toLowerCase().split(' ').join('-'))
    .includes(plot.status);
}

/**
 * @param {object} plot Represents a plot
 * @param {object} typeFilter Value of the type filter
 * @returns {boolean} Wether the plot matches the filter or not
 */
function houseTypeFilter(plot, typeFilter) {
  return typeFilter.indexOf(plot.type) !== -1;
}

/**
 * @param {object} plot Represents a plot
 * @param {object} price Value of the price filter
 * @returns {boolean} Wether the plot matches the filter or not
 */
function priceFilter(plot, price) {
  return plot.price >= price.min && plot.price <= price.max;
}

/**
 * @param {object} plot Represents a plot
 * @param {object} livingSurface Value of the living surface filter
 * @returns {boolean} Wether the plot matches the filter or not
 */
function livingSurfaceFilter(plot, livingSurface) {
  return (
    plot.living_surface >= livingSurface.min &&
    plot.living_surface <= livingSurface.max
  );
}

/**
 *
 * @param {Array} filteredPlots An array of all the filtered plots
 * @param {string} criteria A string that represents the criteria you want to group by
 * @returns {Array} The plots filtered and grouped by status
 */
function groupFilteredPlots(filteredPlots, criteria) {
  const plots = filteredPlots.reduce((result, plot) => {
    let value = null;

    if (criteria === 'status') {
      value = plot.status;
    } else {
      value = plot[criteria];
    }

    const plotList = (result[value] ??= []);

    plotList.push(plot);

    return result;
  }, {});

  return plots;
}

/**
 * @param {object} plot Represents a plot
 * @param {object} plotSurface Value of the plot surface filter
 * @returns {boolean} Wether the plot matches the filter or not
 */
function plotSurfaceFilter(plot, plotSurface) {
  if (!plotSurface) return true;

  return (
    plot.plot_surface >= plotSurface.min && plot.plot_surface <= plotSurface.max
  );
}

/**
 * @param {object} plot Represents a plot
 * @param {object} parkingCount Value of the parking count filter
 * @returns {boolean} Wether the plot matches the filter or not
 */
function parkingCountFilter(plot, parkingCount) {
  if (!parkingCount) return true;

  const parkingCountValue =
    plot.parking_count === null ? 0 : parseInt(plot.parking_count);

  const count = parkingCount
    .replace(' of meer', '')
    .replace('Geen parkeerplaats', 0);

  const selected = parseInt(count);
  return parkingCountValue >= selected;
}

export {
  groupFilteredPlots,
  houseTypeFilter,
  livingSurfaceFilter,
  matchesFilter,
  parkingCountFilter,
  plotSurfaceFilter,
  priceFilter,
  statusFilter,
};
