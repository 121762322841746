import {
  Box,
  Button,
  Divider,
  Heading,
  Hide,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { AdjustmentsHorizontalIcon, MapIcon } from '@heroicons/react/16/solid';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ListItem } from '../Atoms';
import { OrderMenu, Popup } from '../Molecules';
import { matchesFilter, slugify, sortPlots, useMediaQuery } from '../Services';
import Desktop from './Desktop';
import Mobile from './Mobile';
import ItemMobile from '../Atoms/ListItem/ListItemMobile';
import PlotModal from '../Molecules/Popup/PlotModal';

export default function View({
  layers,
  plots,
  filters,
  hotspots,
  statuses,
  initialLayers,
  isFiltersInitiallyOpen,
}) {
  const [sort, setSort] = useState('number');
  const [activeFilters, setActiveFilters] = useState(() =>
    filters.reduce((accumulator, filter) => {
      accumulator[slugify(filter.slug)] = filter.default;
      return accumulator;
    }, {})
  );

  const [filteredPlots, setFilteredPlots] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [activeLayer, setActiveLayer] = useState(initialLayers[0]);
  const [activePlot, setActivePlot] = useState(null);

  const filterPlots = useCallback(() => {
    const filtered = Object.values(plots).filter((plot) =>
      matchesFilter(plot, activeFilters)
    );
    return sortPlots(filtered, sort, statuses);
  }, [plots, activeFilters, sort, statuses]);

  useEffect(() => {
    setFilteredPlots(filterPlots());
  }, [activeFilters, filterPlots]);

  const onFilterChange = useCallback((slug, filterValues) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [slug]: filterValues,
    }));
  }, []);

  const onResetFilters = useCallback(() => {
    setActiveFilters(
      filters.reduce((accumulator, filter) => {
        accumulator[slugify(filter.slug)] = filter.default;
        return accumulator;
      }, {})
    );
  }, [filters]);

  const onLayerChange = useCallback((layer) => {
    setActivePlot(null);
    setActiveLayer(layer);
  }, []);

  const onToggleFilterModal = useCallback(() => {
    setShowFilters((prev) => !prev);
  }, []);

  const onToggleMap = useCallback(() => {
    setShowMap((prev) => !prev);
  }, []);

  const onClosePopup = useCallback(() => {
    setActivePlot(null);
  }, []);

  const onPlotClick = useCallback((plot) => {
    setActivePlot(plot);
  }, []);

  const onSortChange = useCallback(
    (value) => {
      setSort(value);
      const sorted = sortPlots(filteredPlots, value, statuses);
      setFilteredPlots(sorted);
    },
    [filteredPlots, statuses]
  );

  const onHotspotClick = useCallback(
    (hotspot) => {
      if (hotspot.entity_type === 'App\\Models\\Plot') {
        const value = plots[hotspot.entity_id];
        setActivePlot(value);
      } else if (hotspot.entity_type === 'App\\Models\\Layer') {
        const value = layers[hotspot.entity_id];
        setActiveLayer(value);
      } else if (hotspot.entity_type === 'App\\Models\\Url') {
        console.error('URL hotspot functionality not yet implemented', hotspot);
      } else {
        console.error('Unexpected hotspot type', hotspot);
      }
    },
    [plots, layers]
  );

  const statusFilter = useMemo(
    () => filters.find((filter) => filter.slug === 'status'),
    [filters]
  );
  const priceFilter = useMemo(
    () => filters.find((filter) => filter.slug === 'price'),
    [filters]
  );

  const isDesktop = useMediaQuery('(min-width: 768px)');

  return (
    <Box>
      {!isDesktop && (
        <Mobile
          layers={layers}
          initialLayers={initialLayers}
          plots={plots}
          filters={filters}
          hotspots={hotspots}
          statuses={statuses}
          onFilterChange={onFilterChange}
          onResetFilters={onResetFilters}
          onLayerChange={onLayerChange}
          onToggleFilterModal={onToggleFilterModal}
          onToggleMap={onToggleMap}
          onClosePopup={onClosePopup}
          onPlotClick={onPlotClick}
          onHotspotClick={onHotspotClick}
          statusFilter={statusFilter}
          priceFilter={priceFilter}
          filteredPlots={filteredPlots}
          showFilters={showFilters}
          showMap={showMap}
          activeFilters={activeFilters}
          activeLayer={activeLayer}
          activePlot={activePlot}
        >
          <Box width={'full'}>
            <Box
              width={'calc(100% + 3rem)'}
              display={'flex'}
              columnGap={4}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
              mt={2}
              pos={'sticky'}
              zIndex={1}
              top={0}
              bg={'white'}
              mx={-6}
            >
              <Heading
                as="span"
                fontWeight={400}
                fontSize={'2xl'}
                minW={'fit-content'}
                px={6}
                margin={0}
                color={'primary.bg.default'}
              >
                Woningen
              </Heading>

              <Box display={'flex'} flex={'0 0 auto'} ml={'auto'} mr={0}>
                <Button
                  variant={'outline'}
                  onClick={onToggleFilterModal}
                  isActive={showFilters}
                  borderRadius={0}
                  borderColor={'transparent'}
                  borderLeftColor={'primary.border.default'}
                  color={'default.txt'}
                  mr={0}
                  ml={'auto'}
                  minW={14}
                  h={16}
                  gap={2}
                  flex={'0 0 auto'}
                >
                  <Box>
                    <AdjustmentsHorizontalIcon width={16} height={16} />
                  </Box>
                </Button>

                {!isDesktop[0] && (
                  <IconButton
                    variant={'outline'}
                    onClick={onToggleMap}
                    icon={<MapIcon width={16} height={16} />}
                    borderRadius={0}
                    borderColor={'transparent'}
                    borderLeftColor={'primary.border.default'}
                    color={'default.txt'}
                    mr={0}
                    ml={'auto'}
                    w={14}
                    h={16}
                    flex={'0 0 auto'}
                  />
                )}
              </Box>

              <Divider
                orientation="horizontal"
                width="full"
                flexBasis={'100%'}
              />
            </Box>

            <Box width="100%" pb={6} mt={2}>
              <OrderMenu onSortChange={onSortChange} sort={sort} />
            </Box>

            <VStack alignItems="initial" spacing={0}>
              {filteredPlots.length > 0 &&
                filteredPlots.map((plot, index) => (
                  <>
                    <Hide
                      key={`${index}-${plot.entity_id}_${plot.entity_type}-desktop`}
                      below="sm"
                    >
                      <ListItem
                        plot={plot}
                        status={statuses[plot.status]}
                        onPlotClick={onPlotClick}
                        displaySettings={activeLayer.display_settings}
                        isDesktop={isDesktop}
                      />
                    </Hide>
                    <Hide
                      key={`${index}-${plot.entity_id}_${plot.entity_type}-mobile`}
                      above="sm"
                    >
                      <ItemMobile
                        plot={plot}
                        status={statuses[plot.status]}
                        onPlotClick={onPlotClick}
                        displaySettings={activeLayer.display_settings}
                      />
                    </Hide>
                  </>
                ))}
            </VStack>
          </Box>
        </Mobile>
      )}
      {isDesktop && (
        <Desktop
          layers={layers}
          initialLayers={initialLayers}
          plots={plots}
          filters={filters}
          hotspots={hotspots}
          statuses={statuses}
          onFilterChange={onFilterChange}
          onResetFilters={onResetFilters}
          onLayerChange={onLayerChange}
          onToggleFilterModal={onToggleFilterModal}
          onToggleMap={onToggleMap}
          onClosePopup={onClosePopup}
          onPlotClick={onPlotClick}
          onHotspotClick={onHotspotClick}
          statusFilter={statusFilter}
          priceFilter={priceFilter}
          filteredPlots={filteredPlots}
          showFilters={showFilters}
          showMap={showMap}
          activeFilters={activeFilters}
          activeLayer={activeLayer}
          activePlot={activePlot}
          isFiltersInitiallyOpen={isFiltersInitiallyOpen}
          onSortChange={onSortChange}
          sort={sort}
        >
          <Box width="100%">
            <VStack alignItems="initial" spacing={0} pb={10}>
              {filteredPlots.length > 0 &&
                filteredPlots.map((plot, index) => (
                  <ListItem
                    key={`${index}-${plot.entity_id}_${plot.entity_type}`}
                    plot={plot}
                    status={statuses[plot.status]}
                    onPlotClick={onPlotClick}
                    displaySettings={activeLayer.display_settings}
                    isDesktop={isDesktop}
                  />
                ))}
            </VStack>
          </Box>
        </Desktop>
      )}
      {activePlot !== null && !isDesktop && (
        <PlotModal toggleOpen={onClosePopup}>
          <Popup status={statuses[activePlot.status]} plot={activePlot} />
        </PlotModal>
      )}
    </Box>
  );
}

View.propTypes = {
  layers: PropTypes.object.isRequired,
  initialLayers: PropTypes.array.isRequired,
  plots: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  hotspots: PropTypes.array.isRequired,
  statuses: PropTypes.object.isRequired,
  isFiltersInitiallyOpen: PropTypes.bool.isRequired,
};
