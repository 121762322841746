export default function getProjectData(endpoint, uriParam) {
  const API_BASE_ADDRESS = process.env.REACT_APP_API_PROJECTS_ENDPOINT;
  const REACT_APP_API_PROJECT_SLUG = process.env.REACT_APP_API_PROJECT_SLUG;

  const urlParams = new URLSearchParams(window.location.search);
  const parameters =
    uriParam || urlParams.get('project') || REACT_APP_API_PROJECT_SLUG;
  const url = endpoint || API_BASE_ADDRESS;

  return fetch(`${url}/${parameters}`, {
    method: 'GET',
  });
}
