import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export default function PlotModal({ children, toggleOpen }) {
  return (
    <Modal
      isOpen={true}
      onClose={() => toggleOpen(false)}
      size={{ base: 'xs' }}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton
          variant={'solid'}
          backgroundColor={'primary.bg.default'}
          borderRadius={'full'}
          right={3}
          top={3}
          p={5}
          color={'primary.txt'}
          transition="color 0.15s ease-out, background 0.15s ease-out, border 0.15s ease-out"
          _hover={{
            bg: 'primary.bg.darker',
          }}
          _active={{
            bg: 'primary.bg.darkest',
          }}
          _focus={{
            bg: 'primary.bg.darker',
          }}
          zIndex={1}
        />
        <ModalBody p={0}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

PlotModal.propTypes = {
  children: PropTypes.node.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};
