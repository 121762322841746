import {
  Box,
  Checkbox,
  CheckboxGroup,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { groupFilteredPlots } from '../../Services';
import { lightenDarkenColor } from '../../Services/Formatters/Color';
import Base from './Base';
import FilterHeader from './FilterHeader';

export default class StatusFilter extends Base {
  handleChange = (event) => {
    let selected = {};
    event.forEach((key) => {
      selected[key] = this.props.values[key];
    });

    this.props.onChange(this.props.slug, selected);
  };

  render() {
    return (
      <>
        {this.props.label && (
          <FilterHeader
            label={this.props.label}
            resetCallback={this.resetCallback}
            filtersHaveChanged={this.filtersHaveChanged}
            showResetButton={true}
          />
        )}

        <CheckboxGroup
          onChange={this.handleChange}
          defaultValue={Object.keys(this.props.default)}
          value={Object.keys(this.props.selected)}
          spacing={0}
        >
          <Box
            display={'flex'}
            flexDirection={['column', 'row']}
            gap={2}
            flexWrap={this.props.shouldNotWrap ? 'nowrap' : 'wrap'}
          >
            {Object.entries(this.props.values).map((value) => {
              const active = Object.keys(this.props.selected).includes(
                value[0]
              );

              // Set filter option results length and hide the filter option when there are no results.
              const statusArray = groupFilteredPlots(
                this.props.filteredPlots,
                'status'
              );
              const statusValue =
                statusArray[value[0].split(' ').join('-').toLowerCase()];
              const statusResultsAmount = statusValue ? statusValue.length : 0;

              return (
                <FormLabel
                  key={value[0]}
                  bg={active && value[1]}
                  border={`1px solid ${value[1]}`}
                  aria-disabled={active && statusResultsAmount === 0}
                  px={5}
                  py={2}
                  rounded={'2px'}
                  display={'flex'}
                  gap={1}
                  cursor={'pointer'}
                  spacing={0}
                  margin={0}
                  fontSize={'xs'}
                  justifyContent={'center'}
                  flex={'0 0 auto'}
                  userSelect={'none'}
                  _hover={{
                    bg: lightenDarkenColor(value[1], -20),
                  }}
                  _focus={{
                    bg: lightenDarkenColor(value[1], -20),
                  }}
                  _active={{
                    bg: lightenDarkenColor(value[1], -30),
                  }}
                  disabled={statusResultsAmount === 0}
                >
                  <Text
                    as="span"
                    fontWeight={'bold'}
                    _firstLetter={{ textTransform: 'capitalize' }}
                  >
                    {value[0].replace('-', ' ')}
                  </Text>

                  <Text as="span">({statusResultsAmount})</Text>
                  <Checkbox
                    name={this.props.slug}
                    value={value[0]}
                    display="none"
                    disabled={active && statusResultsAmount === 0}
                  />
                </FormLabel>
              );
            })}
          </Box>
        </CheckboxGroup>
      </>
    );
  }
}
