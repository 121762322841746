import { Box, Button } from '@chakra-ui/react';
import { XMarkIcon } from '@heroicons/react/16/solid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  MapControls,
  OrderMenu,
  Popup,
  RangeFilter,
  StatusFilter,
} from '../Molecules';
import PlotMap from './PlotMap';
import { FilterContainer } from './index';
import { slugify } from '../Services';

export default function Desktop({
  children,
  layers,
  initialLayers,
  plots,
  filters,
  hotspots,
  statuses,
  onFilterChange,
  onResetFilters,
  onLayerChange,
  onToggleFilterModal,
  statusFilter,
  priceFilter,
  onHotspotClick,
  onClosePopup,
  activeLayer,
  activePlot,
  activeFilters,
  filteredPlots,
  showFilters,
  isFiltersInitiallyOpen,
  onSortChange,
  sort,
}) {
  const [isListOpen, setIsListOpen] = useState(false);

  const onListOpen = () => {
    setIsListOpen(!isListOpen);
  };

  return (
    <Box px={0}>
      <FilterContainer
        plots={plots}
        filters={filters}
        filterCallback={onFilterChange}
        filterDisplay={showFilters}
        filteredPlots={filteredPlots}
        toggleFilterModal={onToggleFilterModal}
        resetFilters={onResetFilters}
        activeFilterValues={activeFilters}
        isFiltersInitiallyOpen={isFiltersInitiallyOpen}
      />

      <MapControls
        activeLayer={activeLayer}
        layers={layers}
        initialLayers={initialLayers}
        changeActiveLayer={onLayerChange}
        toggleFilterModal={onToggleFilterModal}
        isFilterOpen={showFilters}
        isListOpen={isListOpen}
        onListOpen={onListOpen}
      >
        <>
          {!isListOpen && (
            <Box
              flex={'1 1 100%'}
              px={4}
              display="flex"
              gap={4}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              overflow={'clip'}
              minW={0}
            >
              <Box
                flex={'0 0 auto'}
                overflowX={'scroll'}
                maxW={'100%'}
                h={16}
                display={'flex'}
                alignItems={'center'}
              >
                <StatusFilter
                  slug={slugify(statusFilter.slug)}
                  values={statusFilter.values}
                  default={statusFilter.default}
                  onChange={onFilterChange}
                  filteredPlots={filteredPlots}
                  selected={activeFilters[slugify(statusFilter.slug)]}
                  shouldNotWrap={true}
                />
              </Box>

              {priceFilter && (
                <Box
                  flex={'0 0 auto'}
                  overflow={'clip'}
                  boxSizing={'border-box'}
                  w={'min-content'}
                  pl={4}
                  borderLeft={'1px solid'}
                  borderColor={'primary.border.default'}
                  h={'full'}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <RangeFilter
                    slug={slugify(priceFilter.slug)}
                    values={priceFilter.values}
                    default={priceFilter.default}
                    onChange={onFilterChange}
                    filteredPlots={filteredPlots}
                    selected={activeFilters[slugify(priceFilter.slug)]}
                    hideSlider={true}
                  />
                </Box>
              )}
            </Box>
          )}
          <Box
            display={isListOpen ? 'flex' : 'none'}
            flex={'1 1 100%'}
            px={4}
            gap={4}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            overflow={'clip'}
            minW={0}
          >
            <Box display={!isListOpen ? 'none' : 'flex'} flexShrink={1}>
              <OrderMenu onSortChange={onSortChange} sort={sort} />
            </Box>
          </Box>
        </>
      </MapControls>

      {!isListOpen && (
        <PlotMap
          layers={layers}
          hotspots={hotspots}
          statuses={statuses}
          plots={plots}
          filteredPlots={filteredPlots}
          activeLayer={activeLayer}
          activeFilters={activeFilters}
          activeHotspot={activePlot}
          onHotspotClick={onHotspotClick}
        />
      )}

      {isListOpen && (
        <Box px={4} pt={16} maxW={'6xl'} mx={'auto'}>
          {children}
        </Box>
      )}

      {activePlot && !isListOpen && (
        <Box
          position={'fixed'}
          top={20}
          bottom={4}
          left={4}
          zIndex={1}
          maxW={{ base: 'xs' }}
          w={'full'}
        >
          <Button
            variant={'solid'}
            position={'absolute'}
            right={3}
            top={3}
            p={2.5}
            bg={'primary.bg.default'}
            rounded={'full'}
            onClick={onClosePopup}
            zIndex={2}
          >
            <XMarkIcon width={16} height={16} />
          </Button>

          <Box overflow={'auto'} h={'full'}>
            <Box bg={'white'}>
              <Popup status={statuses[activePlot.status]} plot={activePlot} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

Desktop.propTypes = {
  children: PropTypes.node,
  layers: PropTypes.object.isRequired,
  initialLayers: PropTypes.array.isRequired,
  plots: PropTypes.object.isRequired,
  hotspots: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  statuses: PropTypes.object.isRequired,
  statusFilter: PropTypes.object.isRequired,
  priceFilter: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onLayerChange: PropTypes.func.isRequired,
  onToggleFilterModal: PropTypes.func.isRequired,
  onHotspotClick: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  activeLayer: PropTypes.object.isRequired,
  activePlot: PropTypes.object,
  activeFilters: PropTypes.object.isRequired,
  filteredPlots: PropTypes.array.isRequired,
  showFilters: PropTypes.bool.isRequired,
  isFiltersInitiallyOpen: PropTypes.bool.isRequired,
  onSortChange: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
};
